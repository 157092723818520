/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define([
    'jquery'
], function ($) {
    'use strict';

    return {
        processPool: [],

        /**
         * Set
         *
         * @param name
         * @param modifier
         */
        set: function (name, modifier) {
            this.processPool[name] = modifier;
        },

        /**
         * Get
         *
         * @param name
         * @returns {*}
         */
        get: function (name) {
            return this.processPool[name];
        }
    };
});
